import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
function App() {
  return (
     <>
      <Router>
        <Routes>
          <Route path="/" exact element={<Home/>}/>
        </Routes>
      </Router>
     </>
  );
}

export default App;
